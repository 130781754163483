@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");

* {
  font-family: "Open Sans", sans-serif;
  font-size: 12pt;
}

h1 {
  font-size: 36pt;
}

h2 {
  font-size: 24pt;
  background-color: rgba(0, 51, 160, 0.1);
}

body {
  margin: 10px;
  padding: 0px;
  background-color: white;
  text-align: center;
}

button {
  margin: 10px;
  background-color: rgba(0, 51, 160);
  color: white;
  padding: 10px;
}
button:hover {
  opacity: 60%;
  text-decoration: underline;
}
button:disabled {
  background-color: lightgrey;
  color: grey;
  text-decoration: none;
  opacity: 100%;
}

textarea {
  width: 95%;
  margin: 10px;
  padding: 5px;
  height: 100px;
  border-radius: 15pt;
  border: thin solid #d3d3d3;
}

ul,
ol {
  text-align: left;
  list-style-type: decimal;
}

.tab {
  margin-left: 40px;
  background-color: beige;
  color: black;
}

.tab:hover {
  background-color: #f0f0f0;
}

.tabContent {
  padding: 10px;
  border: 1px solid #ccc;
  border-top: none;
  text-align: left;
}

.answer {
  width: 95%;
  margin: 10px;
  padding: 5px;
  border-radius: 15pt;
  border: thin solid #d3d3d3;
  background-color: antiquewhite;
}

.resetButton {
  background-color: red;
}

.container {
  display: flex; /* Enables Flexbox layout */
  justify-content: space-between; /* Distributes space between columns */
}

.col {
  flex: 1; /* Each column takes up equal space */
  padding: 10px; /* Optional: Adds some space inside each column */
}
